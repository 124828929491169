<template>
  <div class="offline" ref="container">
    <mt-navbar v-model="selected">
      <mt-tab-item id="1">{{ openClassLesson.cate_name }}</mt-tab-item>
      <mt-tab-item id="2">{{ concealLesson.cate_name }}</mt-tab-item>
    </mt-navbar>

    <!-- tab-container -->
    <mt-tab-container v-model="selected">
      <mt-tab-container-item id="1">
        <div v-if="openClassLesson.list != false">
          <div
            class="list"
            v-for="(item, index) in openClassLesson.list"
            :key="index"
          >
            <router-link
              :to="{
                path: `/lesson/offline/${item.id}`,
              }"
            >
              <p class="title">{{ item.cate_name }}</p>
              <img v-lazy="item.images" />
              <div class="bottom">
                <div>
                  <b>{{ item.bookname }}</b>
                  <p>{{ item.intro }}</p>
                  <ul>
                    <li>报名人数：{{ item.num }}人</li>
                    <li class="text-right" v-show="parseInt(item.price) > 0">
                      价格：
                      <span class="gold-num">￥{{ item.price }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <div v-else class="emptyData" style="margin-top: 5rem">
          <img src="@assets/images/emptyData.png" />
          <p>暂无数据</p>
        </div>
      </mt-tab-container-item>

      <mt-tab-container-item id="2">
        <van-tabs
          v-model="classIndex"
          color="#DAA070"
          title-active-color="#DAA070"
        >
          <van-tab
            v-for="(item, i) in concealLesson.category"
            :key="i"
            :title="item.cate_name"
            :title-style="{ fontSize: '.26rem', marginTop: '.1rem' }"
          >
            <div v-if="item.category.length">
              <div class="category" :ref="'categoryEle' + i">
                <ul>
                  <span>分类：</span>
                  <li @click="all(i)" :class="{ on: current === 999 }">全部</li>
                  <li
                    @click="categorySelect(category.id, i, k)"
                    :class="{ on: k === current }"
                    v-for="(category, k) in item.category"
                    :key="k"
                  >
                    {{ category.cate_name }}
                  </li>
                </ul>
              </div>
            </div>

            <div
              v-if="nodeClassList.length"
              :style="{ paddingTop: categoryEleHeight }"
            >
              <div class="list" v-for="(list, j) in nodeClassList" :key="j">
                <router-link
                  :to="{
                    path: `/lesson/offline/${list.id}`,
                  }"
                >
                  <p class="title">{{ list.cate_name }}</p>
                  <img v-lazy="list.images" />
                  <div class="bottom">
                    <div>
                      <b>{{ list.bookname }}</b>
                      <p>{{ list.intro }}</p>
                      <ul>
                        <li>报名人数：{{ list.num }}人</li>
                        <li class="text-right">
                          价格：
                          <span class="gold-num">￥{{ list.price }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
            <div v-else class="emptyData" style="margin-top: 5rem">
              <img src="@assets/images/emptyData.png" />
              <p>暂无数据</p>
            </div>
          </van-tab>
        </van-tabs>
      </mt-tab-container-item>
    </mt-tab-container>
     <Footer :current=2></Footer>
  </div>
</template>

<script>
import Vue from "vue";
import { Tab, Tabs } from "vant";
import { offlineList } from "@api/public";
import Footer from "@components/Footer";
Vue.use(Tab);
Vue.use(Tabs);
export default {
  name: "offline-list",
  components: {
    Footer
  },
  data() {
    return {
      classIndex: null,
      current: 999,
      openClassLesson: {},
      concealLesson: {},
      nodeClassList: [],
      pageSize: 10,
      selected: "1",
      categoryEleHeight: "",
      site_id:0,
    };
  },
  created() {
    window.location.href = "/testh5/pages/class/index"
    this.site_id = this.$route.query.site_id;
    if(this.site_id > 0 ){
        sessionStorage.setItem("lessonIndex", 2);
        sessionStorage.setItem('site_id',this.site_id);
    }
    if (sessionStorage.getItem("lessonIndex")) {
      this.selected = sessionStorage.getItem("lessonIndex");
    }
  },
  watch: {
    selected(n) {
      sessionStorage.setItem("lessonIndex", n);
      this.$nextTick(() => {
        this.getSpace(0);
      });
    },
    current(n) {
      let info = {
        id:
          n != 999
            ? this.concealLesson.category[this.classIndex].category[n].id
            : null,
        i: this.classIndex,
        k: n,
      };
      sessionStorage.setItem("concealLesson", JSON.stringify(info));
    },
    classIndex(n) {
      let that = this;
      that.current = 999;
      that.nodeClassList = that.concealLesson.category[n || 0].list;
      that.$nextTick(() => {
        that.getSpace(n || 0);
      });
      let info = {
        id: null,
        i: n,
        k: 999,
      };
      sessionStorage.setItem("concealLesson", JSON.stringify(info));
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 获取课程间距
    getSpace(n) {
      let that = this;
      let ele = "categoryEle" + n;
      if (that.$refs[ele]) {
        that.categoryEleHeight = that.$refs[ele][0].offsetHeight + 44 + "px";
      }
    },

    // 获取列表数据
    getList() {
      let that = this;

      that.$dialog.loading.open();
      offlineList()
        .then((res) => {
          that.$set(that, "openClassLesson", res.data[0]);
          that.$set(that, "concealLesson", res.data[1]);
          that.$set(that, "nodeClassList", res.data[1].category[0].list);
          that.$dialog.loading.close();
          // 匠心商学院密训课程分类

          if (sessionStorage.getItem("concealLesson")) {
            let info = JSON.parse(sessionStorage.getItem("concealLesson"));
            if (info.index !== "") {
              that.classIndex = info.i;
              that.$nextTick(() => {
                that.current = info.k;
                that.categorySelect(info.id, info.i, info.k);
              });
            }
          }
        })
        .catch((err) => {
          that.$dialog.loading.close();
          that.$dialog.error(err.msg);
        });
    },

    /**
     * 商学院密训课程选择分类渲染数据
     * @param id 密训课程分类id
     * @param i 密训课程索引
     * @param k 密训课程分类索引
     */
    categorySelect(id, i, k) {
      let that = this;
      if (id) {
        that.nodeClassList = that.concealLesson.category[i].list.filter(
          (item) => item.cid === id
        );
      } else {
        that.nodeClassList = that.concealLesson.category[i].list;
      }
      that.current = k;
    },

    /**
     * 渲染商学院密训全部数据
     * @param i 密训课程索引
     */
    all(i) {
      let that = this;
      that.nodeClassList = that.concealLesson.category[i].list;
      that.current = 999;
    },
  },
};
</script>

<style lang="scss" scoped>
.offline {
  padding-bottom: 1rem;
  position: relative;
  .mint-navbar {
    height: 1rem;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    border-bottom: 0.1rem solid #f5f5f5;
  }
  .mint-tab-container {
    padding-top: 1rem;
    .mint-tab-container-item {
      position: relative;
      .category {
        position: fixed;
        top: 1.88rem;
        left: 0;
        background: white;
        padding: 0.2rem 0.3rem 0.1rem;
        width: 100%;
        z-index: 999;
        ul {
          overflow: hidden;
          position: relative;
          padding-left: 0.8rem;
          span {
            position: absolute;
            left: 0;
            top: 0;
            font-size: 0.26rem;
            margin-top: 0.16rem;
          }
          li {
            float: left;
            font-size: 0.24rem;
            padding: 0.06rem 0.24rem;
            margin: 0.1rem 0.1rem;
            background: #f5f5f5;
            border-radius: 0.04rem;
          }
          .on {
            background: black;
            color: white;
          }
        }
      }
      .list {
        padding: 0 0.3rem 0.3rem;
        background: white;
        p.title {
          height: 0.8rem;
          line-height: 0.8rem;
          font-size: 0.28rem;
          font-weight: 700;
          overflow: hidden;
        }
        img {
          width: 100%;
          height: auto;
          border-radius: 0.08rem 0.08rem 0 0;
          display: block;
        }
        image[lazy="loading"] {
          width: 100%;
          height: auto;
          margin: auto;
        }
        .bottom {
          width: 100%;
          box-shadow: 0 0.06rem 0.06rem #ddd;
          border-radius: 0 0 0.08rem 0.08rem;
          div {
            padding: 0.2rem 0.2rem 0;
            b {
              font-size: 0.26rem;
            }
            p {
              font-size: 0.26rem;
              margin: 0.1rem 0;
              line-height: 0.36rem;
              color: #a9a9a9;
            }
            ul {
              width: 100%;
              height: auto;
              overflow: hidden;
              padding-bottom: 0.2rem;
              li {
                float: left;
                width: 50%;
                height: 0.5rem;
                line-height: 0.5rem;
                font-size: 0.26rem;
                overflow: hidden;
              }
            }
          }
        }
      }
    }
  }
}
</style>
